<template>
  <div data-private="redact">
    <label
      v-html="labelText(accessKey, label)"
      v-if="label && !noLabel"
      :for="$attrs.id || 'SSN'"
    />
    <DxTextBox
      ref="ssnBox"
      v-bind="$attrs"
      class="form-control p-0"
      :class="{
        'is-invalid':
          (validator && validator.$model && validator.$invalid) || (validator && validator.$error),
        'is-valid': validator && validator.$model && !validator.$invalid,
        'has-error': validator && validator.$model && validator && validator.$error,
        'has-search-value': hasSearchValue && !highlightInvalid
      }"
      :value="displayValue"
      @value-changed="updateValue"
      @input="handleInput"
      name="SSN"
      :disabled="disabled || !permissions.SocialSecurityNumberView"
      id="ssnInput"
      @focusIn="onFocus"
      @focusOut="onBlur"
    />
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        :class="{ 'd-block': validator.$invalid }"
        class="validation-errors"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error mb-2" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DxTextBox from "devextreme-vue/text-box";
import { formatSSN, validatorMsgMapBase } from "@/modules/helpers";

export default {
  components: { DxTextBox },
  props: {
    label: {
      default: "SSN"
    },
    value: {
      type: String
    },
    noLabel: {
      default: false
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    highlightSearchValue: {
      default: false
    },
    accessKey: {
      default: null
    },
    disabled: {
      default: false
    }
  },
  data() {
    return {
      displayValue: "",
      isFocused: false
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    hasSearchValue() {
      if (!this.highlightSearchValue) {
        return false;
      }
      if (this.value !== null && this.value !== undefined && this.value !== "") {
        if (Array.isArray(this.value)) {
          return this.value.length > 0;
        }
        return true;
      }
      return false;
    },
    textBox() {
      return this.$refs.ssnBox;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.displayValue = formatSSN(nv);
        } else {
          this.displayValue = "";
        }
        this.updateValue({ value: nv });
      }
    }
  },
  methods: {
    updateValue(data) {
      const { value } = data;
      return this.$emit("input", value?.replaceAll("-", "") || value);
    },
    handleInput({ event }) {
      const value = event?.currentTarget?.value;
      if (value) {
        this.displayValue = formatSSN(value) || value;
      } else {
        this.displayValue = "";
      }
    },
    labelText(key = "", name) {
      if (key && this.label && !this.noLabel) {
        const regex = new RegExp(key, "i");
        if (regex.test(name)) {
          const { index } = name.match(regex);
          return `<b>${name.slice(0, index)}<u>${name[index]}</u>${name.slice(index + 1)}</b>`;
        }
      }
      return `<b>${name || ""}</b>`;
    },
    focus() {
      if (this.textBox?.instance?.focus) {
        this.textBox?.instance?.focus();
        this.isFocused = true;
      }
    },
    onBlur(event) {
      this.isFocused = false;
      this.$emit("blur", event);
    },
    onFocus() {
      this.isFocused = true;
      this.$emit("focus");
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="edit-body">
    <h2>Editing Patient Data</h2>
    Leave field blank if no change.
    <form @submit="handlePreview">
      <div class="row">
        <text-input
          data-private="redact"
          v-model="originalData.lastName"
          label="Current Last Name"
          class="col"
          :disabled="true"
        />
        <text-input
          data-private="redact"
          v-focus
          v-model="updatedData.lastName"
          label="New Last Name"
          class="col"
        />
      </div>
      <div class="row">
        <text-input
          data-private="redact"
          v-model="originalData.firstName"
          label="Current Last Name"
          class="col"
          :disabled="true"
        />
        <text-input
          data-private="redact"
          v-model="updatedData.firstName"
          label="New First Name"
          class="col"
        />
      </div>
      <div class="row">
        <text-input
          data-private="redact"
          v-model="originalData.middleName"
          label="Current Middle Name"
          class="col"
          :disabled="true"
        />
        <text-input
          data-private="redact"
          v-model="updatedData.middleName"
          label="New Middle Name"
          class="col"
        />
      </div>
      <div class="row">
        <SsnInput v-model="originalData.ssn" label="Current SSN" class="col" :disabled="true" />
        <SsnInput v-model="updatedData.ssn" label="New SSN" class="col" />
      </div>
      <div class="row">
        <date-picker
          data-private="redact"
          v-model="originalData.dateOfBirth"
          label="Current DOB"
          class="col"
          :disabled="true"
        />
        <date-picker
          data-private="redact"
          v-model="updatedData.dateOfBirth"
          label="New DOB"
          class="col"
          :twoDigitYear="true"
          :max="today"
        />
      </div>
      <div class="row">
        <text-input
          data-private="redact"
          v-model="originalData.mrn"
          label="Current MRN"
          class="col"
          :disabled="true"
        />
        <text-input data-private="redact" v-model="updatedData.mrn" label="New MRN" class="col" />
      </div>
      <Address class="address-box m-2 mt-3" v-model="updatedData.address" />
      <div class="my-2 d-flex justify-content-end align-items-center">
        <loader v-if="isLoading" size="small" />
        <button class="btn btn-danger" type="button" @click="handleCancel">Cancel</button>
        <button class="btn btn-success ml-2" type="submit">Preview</button>
      </div>
    </form>
    <modal :status="isPreviewOpen" @close="togglePreview">
      <h3 class="m-2">The following data will be set for the patient record:</h3>
      <div v-if="preview.newCaseValues" class="fields-table">
        <div v-for="field of Object.keys(preview.newCaseValues)" v-bind:key="field" class="row">
          <div class="col-4">{{ fieldNameEnum[field] }}</div>
          <div data-private="redact" class="col">
            {{
              field === "dateOfBirth"
                ? formatDate(preview.newCaseValues.dateOfBirth || originalData.dateOfBirth)
                : preview.newCaseValues[field] || originalData[field]
            }}
          </div>
        </div>
        <div v-if="previewPrimaryAddress" class="mt-2">
          Primary Address:
          <div data-private="redact" class="address-line">{{ previewPrimaryAddress.line1 }}</div>
          <div data-private="redact" v-if="previewPrimaryAddress.line2" class="address-line">
            {{ previewPrimaryAddress.line2 }}
          </div>
          <div class="address-line mb-2">
            {{ previewPrimaryAddress.city }}, {{ getStateName(previewPrimaryAddress.state) }}
            {{ previewPrimaryAddress.zipCode }}
          </div>
        </div>
        <div v-if="previewSecondaryAddresses.length">
          Secondary Address{{ previewSecondaryAddresses.length > 1 ? "es" : "" }}:
        </div>
        <div v-for="address of previewSecondaryAddresses" v-bind:key="address.id">
          <div data-private="redact" class="address-line">{{ address.line1 }}</div>
          <div data-private="redact" v-if="address.line2" class="address-line">
            {{ address.line2 }}
          </div>
          <div class="address-line mb-2">
            {{ address.city }}, {{ getStateName(address.state) }}
            {{ address.zipCode }}
          </div>
        </div>
      </div>
      <div class="case-table mr-2">
        <h3>This will also update the patient demographic data for the following cases:</h3>
        <div class="row">
          <div
            v-for="caseDetails of halfOfCases(false)"
            v-bind:key="caseDetails.caseId"
            class="col-6"
          >
            <div class="row">
              <div class="col-5" :class="checkIfSame(caseDetails)">
                {{ caseDetails.caseNumber }}
              </div>
              <div class="col-7">
                <span data-private="redact" :class="checkIfSame(caseDetails, 'lastName')">
                  {{ caseDetails.lastName }} </span
                >,
                <span data-private="redact" :class="checkIfSame(caseDetails, 'firstName')"
                  >{{ caseDetails.firstName }}
                </span>
                <span data-private="redact" :class="checkIfSame(caseDetails, 'middleName')"
                  >{{ caseDetails.middleName }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-for="caseDetails of halfOfCases(true)"
            v-bind:key="caseDetails.caseId"
            class="col-6"
          >
            <div class="row">
              <div class="col-5" :class="checkIfSame(caseDetails)">
                {{ caseDetails.caseNumber }}
              </div>
              <div class="col-7">
                <span data-private="redact" :class="checkIfSame(caseDetails, 'lastName')">
                  {{ caseDetails.lastName }} </span
                >,
                <span data-private="redact" :class="checkIfSame(caseDetails, 'firstName')"
                  >{{ caseDetails.firstName }}
                </span>
                <span data-private="redact" :class="checkIfSame(caseDetails, 'middleName')"
                  >{{ caseDetails.middleName }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="m-2 d-flex justify-content-end align-items-center">
          <loader v-if="isLoading" size="small" />
          <button class="btn btn-danger" type="button" @click="togglePreview">Cancel</button>
          <button class="btn btn-success ml-2" type="submit" @click="handleCommit">Commit</button>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import TextInput from "./common/TextInput.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import { createLogItem } from "@/modules/helpers";
import { mapState } from "vuex";
import Address from "./forms/Address.vue";
import { AuditLogApi, CaseHistoryApi } from "@/services";
import Modal from "./common/Modal.vue";
import { handleErrors } from "@/modules/handleErrors";
import moment from "moment";
import Loader from "./common/Loader.vue";
import { AuditLogItems } from "@/modules/enums";
import SsnInput from "./common/SsnInput.vue";

export default {
  components: { TextInput, DatePicker, Address, Modal, Loader, SsnInput },
  props: ["originalData"],
  data() {
    return {
      isLoading: false,
      updatedData: {
        id: null,
        mrn: null,
        ssn: null,
        dateOfBirth: null,
        firstName: null,
        lastName: null,
        middleName: null,
        address: []
      },
      preview: {},
      previewPrimaryAddress: {},
      previewSecondaryAddresses: [],
      isPreviewOpen: false,
      fieldNameEnum: {
        lastName: "Last Name",
        firstName: "First Name",
        middleName: "Middle Name",
        ssn: "SSN",
        dateOfBirth: "DOB",
        mrn: "MRN",
        address1: "Address 1",
        address2: "Address 2",
        city: "City",
        stateId: "State",
        zipCode: "ZIP"
      },
      today: new Date()
    };
  },
  computed: {
    ...mapState({
      states: state => state.dropdowns.states
    })
  },
  mounted() {
    this.$store.dispatch("dropdowns/getStates");
    this.updatedData.id = this.originalData.id;
    this.updatedData.address = this.originalData.address;
  },
  methods: {
    handleCancel() {
      this.$emit("close");
    },
    async handlePreview(event) {
      event.preventDefault();
      this.isLoading = true;
      const { updatedData } = this;
      const payload = {
        patientId: updatedData.id,
        lastName: updatedData.lastName,
        firstName: updatedData.firstName,
        middleName: updatedData.middleName,
        ssn: updatedData.ssn,
        dateOfBirth: updatedData.dateOfBirth,
        mrn: updatedData.mrn,
        newAddressValues: updatedData.address
      };
      const preview = await CaseHistoryApi.updatePatient({
        ...payload,
        previewOnly: true
      });
      this.previewPrimaryAddress = preview.newAddressValues.find(e => e.isPrimary === true);
      this.previewSecondaryAddresses = preview.newAddressValues.filter(e => e.isPrimary === false);
      this.payload = payload;
      this.preview = preview;
      this.togglePreview();
      this.isLoading = false;
    },
    togglePreview() {
      this.isPreviewOpen = !this.isPreviewOpen;
    },
    async handleCommit(event) {
      event.preventDefault();
      this.isLoading = true;
      try {
        const response = await CaseHistoryApi.updatePatient({
          ...this.payload,
          previewOnly: false
        });
        if (response) {
          window.notify("Updated case details.");
          this.$emit("close");
          this.$emit("commit");
          const logItem = createLogItem({}, AuditLogItems.Other);
          logItem.comments = `Updated patient history record: ${JSON.stringify(response, null, 2)}`;
          AuditLogApi.insertLogMessage(logItem);
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    getStateName(stateId) {
      const state = this.states.find(e => e.id === stateId);
      return state.displayName;
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    halfOfCases(isSecond) {
      const { oldCases } = this.preview;
      if (!oldCases) {
        return [];
      }
      if (oldCases.length < 50) {
        return oldCases;
      }
      const midpoint = Math.ceil(oldCases.length / 2);
      if (isSecond) {
        return oldCases.slice(0, midpoint);
      } else {
        return oldCases.slice(midpoint, oldCases.length);
      }
    },
    checkIfSame(caseDetails, property) {
      if (
        property &&
        this.updatedData[property] &&
        caseDetails[property] !== this.updatedData[property]
      ) {
        return "changed";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.address-box {
  width: 95%;
}
.fields-table {
  width: 80%;
  margin: auto;
}
.address-line {
  margin-left: 2rem;
}
.edit-body {
  width: 95%;
  margin: auto;
}
.case-table {
  max-width: 800px;
}
.changed {
  background-color: yellow;
}
</style>

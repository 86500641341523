<template>
  <container class="container">
    <h1>Patient History Maintenance</h1>
    <h3 class="mt-3" v-if="!selectedPatientId">
      Please select a case number or patient name to begin.
    </h3>
    <div class="row">
      <div class="col-3">
        <text-input
          v-focus
          label="Case Number"
          v-model="caseNumberSearch"
          name="caseNumberSearch"
          @blur="handleChangeCaseSearch"
        />
        <div class="ml-2 text-danger" v-if="caseNotFound">No matching case found</div>
      </div>
      <select-input
        data-private="redact"
        label="Patient Name"
        class="col-3"
        v-model="selectedPatientId"
        name="selectedPatientId"
        :searchExpr="['lastName', 'firstName']"
        :displayExpr="patientDropDownDisplay"
        :dataSource="patientDataSource"
      />
    </div>
    <div class="d-flex flex-row" v-if="patientData.id">
      <div>
        <h3>
          Patient History for
          <span data-private="redact">
            {{ patientData.firstName }} {{ patientData.middleName }}
            {{ patientData.lastName }}</span
          >
        </h3>
        <div data-private="redact">
          <b>{{ patientDataDisplay }}</b>
        </div>
      </div>
      <div class="ml-2">
        <button class="mx-1 btn btn-info" @click="toggleEditPopup">Edit</button>
        <button class="mx-1 btn btn-info" @click="toggleMergePopup">Merge</button>
      </div>
    </div>
    <dx-grid-with-search
      v-if="selectedPatientId"
      :dataSource="historyDataSource"
      :columns="columns"
      :toolbar="toolbar"
      :columnChooser="false"
      :searchPanel="searchPanel"
      @initialized="initGrid"
    >
      <template v-slot:extraActions>
        <add-button @click="toggleAddCasePopup" v-tooltip="'Click to add case to history.'" />
      </template>
    </dx-grid-with-search>
    <modal :status="isAddCasePopupOpen" @close="toggleAddCasePopup">
      <add-case-to-patient
        :patientData="patientData"
        @close="toggleAddCasePopup"
        @commit="handleCommit"
      />
    </modal>
    <modal :status="isEditPopupOpen" @close="toggleEditPopup">
      <edit-patient-data
        :originalData="patientData"
        @close="toggleEditPopup"
        @commit="handleEditPatient"
      />
    </modal>
    <modal :status="isMergePopupOpen" @close="toggleMergePopup">
      <merge-patient-data
        :currentPatient="patientData"
        @close="toggleMergePopup"
        @commit="handleCommit"
      />
    </modal>
    <modal :status="isUnlinkPopupOpen" @close="closeUnlinkPopup">
      <unlink-cases-from-history
        :cases="casesToUnlink"
        @close="closeUnlinkPopup"
        @commit="handleCommit"
      />
    </modal>
  </container>
</template>
<script>
import TextInput from "./common/TextInput.vue";
import SelectInput from "./common/SelectInput.vue";
import DataSource from "devextreme/data/data_source";
import { CasesApi } from "@/services";
import { calculateAccessionNumberingWithoutPrevCentury, formatSSN } from "@/modules/helpers";
import AddButton from "./common/AddButton.vue";
import Modal from "./common/Modal.vue";
import moment from "moment";
import EditPatientData from "./EditPatientData.vue";
import Container from "./common/Container.vue";
import MergePatientData from "./MergePatientData.vue";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";
import UnlinkCasesFromHistory from "./UnlinkCasesFromHistory.vue";
import AddCaseToPatient from "./AddCaseToPatient.vue";

export default {
  components: {
    TextInput,
    SelectInput,
    AddButton,
    Modal,
    EditPatientData,
    Container,
    MergePatientData,
    DxGridWithSearch,
    UnlinkCasesFromHistory,
    AddCaseToPatient
  },
  data() {
    return {
      selectedPatientId: null,
      caseNumberSearch: "",
      patientData: {
        id: null,
        mrn: null,
        ssn: null,
        dateOfBirth: null,
        firstName: null,
        lastName: null,
        maidenName: null,
        middleName: null,
        suffix: null,
        sex: null,
        race: null,
        accountNumber: null,
        lastProvider: null,
        address: [],
        email: [],
        phoneNumbers: []
      },
      isAddCasePopupOpen: false,
      isEditPopupOpen: false,
      isMergePopupOpen: false,
      isUnlinkPopupOpen: false,
      caseNotFound: false,
      searchPanel: { visible: false },
      casesToUnlink: []
    };
  },
  computed: {
    patientDataSource() {
      return new DataSource({
        store: CasesApi.patientMatch,
        sort: "lastName"
      });
    },
    historyDataSource() {
      return new DataSource({
        store: this.selectedPatientId ? CasesApi.searchStore : [],
        filter: ["patientId", "=", this.selectedPatientId]
      });
    },
    columns() {
      return [
        {
          dataField: "accessionNumber",
          caption: "Case #"
        },
        {
          dataField: "patientName"
        },
        {
          dataField: "patientSSN",
          caption: "SSN",
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "patientDOB",
          caption: "DOB",
          dataType: "date"
        },
        {
          dataField: "patientMRN",
          caption: "MRN"
        }
        // Address
      ];
    },
    patientDataDisplay() {
      const { dateOfBirth, ssn, mrn } = this.patientData;
      let fields = [];
      if (dateOfBirth) {
        fields.push(`DOB: ${this.formatDate(dateOfBirth)}`);
      }
      if (ssn) {
        fields.push(`SSN: ${ssn}`);
      }
      if (mrn) {
        fields.push(`MRN: ${mrn}`);
      }
      return fields.join(" | ");
    },
    toolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          },
          {
            widget: "dxButton",
            options: {
              icon: "fa fa-unlink",
              onClick: this.openUnlinkPopup,
              hint: "Click to unlink selected cases",
              elementAttr: {
                "data-testId": "toolbar-unlink",
                class: "icon-color"
              },
              text: "Unlink"
            },
            locateInMenu: "auto",
            showText: "inMenu"
          }
        ]
      };
    }
  },
  watch: {
    selectedPatientId: {
      handler(nv, ov) {
        if (nv !== ov) {
          if (nv) {
            CasesApi.getPatientById(nv).then(res => {
              this.patientData = res;
            });
          } else {
            this.patientData = {
              id: null,
              mrn: null,
              ssn: null,
              dateOfBirth: null,
              firstName: null,
              lastName: null,
              maidenName: null,
              middleName: null,
              suffix: null,
              sex: null,
              race: null,
              accountNumber: null,
              lastProvider: null,
              address: [],
              email: [],
              phoneNumbers: []
            };
            this.caseNumberSearch = "";
          }
        }
      }
    }
  },
  methods: {
    patientDropDownDisplay(data) {
      if (!data) {
        return "";
      }
      return `${data?.lastName}, ${data?.firstName} ${
        data.dateOfBirth ? "(" + this.formatDate(data.dateOfBirth) + ")" : ""
      }`;
    },
    async handleChangeCaseSearch(event) {
      const { value } = event.target;
      if (value) {
        const caseNumber = this.calculateFullCaseNumber(value);
        const { prefix, number } = caseNumber;
        this.caseNumberSearch = prefix + number;
        const cases = await CasesApi.searchStore.load({
          filter: ["accessionNumber", prefix?.length ? "=" : "contains", prefix + number]
        });
        if (cases.length === 1 && cases[0]?.patientId) {
          this.caseNotFound = false;
          this.selectedPatientId = cases[0]?.patientId;
        } else {
          this.caseNotFound = true;
        }
      }
    },
    calculateFullCaseNumber(caseNumber) {
      let prefix = "";
      if (this.hasPrefix(caseNumber)) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
        prefix = regex.groups.prefix.toUpperCase();
      }
      let targetNumber = this.caseNumberWithoutPrefix(caseNumber);
      if (targetNumber.replace("-", "").length < 11) {
        targetNumber = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
      }
      return { prefix, number: targetNumber };
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    toggleAddCasePopup() {
      this.isAddCasePopupOpen = !this.isAddCasePopupOpen;
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    toggleEditPopup() {
      this.isEditPopupOpen = !this.isEditPopupOpen;
    },
    toggleMergePopup() {
      this.isMergePopupOpen = !this.isMergePopupOpen;
    },
    closeUnlinkPopup() {
      this.isUnlinkPopupOpen = false;
      this.casesToUnlink = [];
    },
    openUnlinkPopup() {
      const cases = this.grid.getSelectedRowsData();
      if (!cases?.length) {
        window.alert("Please select cases to unlink.");
        return;
      }
      this.casesToUnlink = cases;
      this.isUnlinkPopupOpen = true;
    },
    initGrid(event) {
      this.grid = event.component;
      this.initialized$.next(event);
    },
    handleCommit() {
      this.grid.refresh();
      this.grid.clearSelection();
    },
    handleEditPatient() {
      this.grid.refresh();
      CasesApi.getPatientById(this.selectedPatientId).then(res => {
        this.patientData = res;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background: $white;
  border-radius: 3px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
}
</style>

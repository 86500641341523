<template>
  <div v-shortkey="submitShortkey" @shortkey="handleShortkey">
    <h2>Unlink Case{{ cases.length > 1 ? "s" : "" }}</h2>
    <div v-for="caseData of cases" v-bind:key="caseData.caseId" class="mb-2 row">
      <div class="col">{{ caseData.accessionNumber }}</div>
      <div data-private="redact" class="col">{{ caseData.patientName }}</div>
    </div>
    <checkbox
      name="existingRecord"
      id="existingRecord"
      :value="!isNewRecord"
      label="Link to existing patient record"
      @input="toggleIsNewRecord"
    />
    <checkbox
      name="newRecord"
      id="newRecord"
      :value="isNewRecord"
      label="Create new patient record"
      @input="toggleIsNewRecord"
    />
    <div v-if="!isNewRecord" class="row">
      <div class="col">
        <text-input
          label="Case Number"
          v-model="caseNumberSearch"
          name="caseNumberSearch"
          @blur="handleChangeCaseSearch"
        />
        <div class="ml-2 text-danger" v-if="caseNotFound">No matching case found</div>
      </div>
      <select-input
        data-private="redact"
        label="Patient Name"
        class="col"
        v-model="selectedPatientId"
        name="selectedPatientId"
        :searchExpr="['lastName', 'firstName']"
        :displayExpr="patientDropDownDisplay"
        :dataSource="patientDataSource"
      />
    </div>
    <div class="my-2 d-flex justify-content-end align-items-center">
      <loader v-if="isLoading" size="small" />
      <button class="btn btn-danger" type="button" @click="handleCancel">Cancel</button>
      <button
        class="btn btn-success ml-2"
        type="submit"
        @click="handlePreview"
        :disabled="!isNewRecord && !selectedPatientId"
      >
        Preview
      </button>
    </div>
    <modal :status="isPreviewOpen" @close="togglePreview">
      <div v-if="preview.length">
        <h3 v-if="!isNewRecord">
          The following cases will be moved to the patient record for
          <span data-private="redact">{{ newPatientName }}</span
          >:
        </h3>
        <h3 v-else>The following cases will be added to a new patient record:</h3>
        <div v-for="caseDetails of preview" v-bind:key="caseDetails.caseId">
          {{ caseDetails.caseNumber }}
        </div>
      </div>
      <div class="my-2 d-flex justify-content-end align-items-center">
        <loader v-if="isLoading" size="small" />
        <button class="btn btn-danger" type="button" @click="togglePreview">Cancel</button>
        <button class="btn btn-success ml-2" type="submit" @click="handleCommit">Commit</button>
      </div>
    </modal>
  </div>
</template>

<script>
import moment from "moment";
import Checkbox from "./common/Checkbox.vue";
import SelectInput from "./common/SelectInput.vue";
import TextInput from "./common/TextInput.vue";
import DataSource from "devextreme/data/data_source";
import { AuditLogApi, CaseHistoryApi, CasesApi } from "@/services";
import {
  altKey,
  calculateAccessionNumberingWithoutPrevCentury,
  createLogItem
} from "@/modules/helpers";
import Modal from "./common/Modal.vue";
import { handleErrors } from "@/modules/handleErrors";
import Loader from "./common/Loader.vue";
import { AuditLogItems } from "@/modules/enums";
export default {
  components: { Checkbox, TextInput, SelectInput, Modal, Loader },
  props: ["cases"],
  data() {
    return {
      isNewRecord: false,
      caseNumberSearch: "",
      selectedPatientId: null,
      selectedPatientData: {},
      caseNotFound: false,
      preview: {},
      isPreviewOpen: false,
      payload: {},
      newPatientName: "",
      submitShortkey: altKey("s"),
      isLoading: false
    };
  },
  computed: {
    patientDataSource() {
      return new DataSource({
        store: CasesApi.patientMatch,
        sort: "lastName"
      });
    }
  },
  methods: {
    toggleIsNewRecord() {
      this.isNewRecord = !this.isNewRecord;
    },
    patientDropDownDisplay(data) {
      if (!data) {
        return "";
      }
      return `${data?.lastName}, ${data?.firstName} ${
        data.dateOfBirth ? "(" + this.formatDate(data.dateOfBirth) + ")" : ""
      }`;
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    async handleChangeCaseSearch(event) {
      const { value } = event.target;
      let prefix = "";
      if (value) {
        if (this.hasPrefix(value)) {
          const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
          prefix = regex.groups.prefix.toUpperCase();
        }
        let targetNumber = this.caseNumberWithoutPrefix(value);
        let number = null;
        if (targetNumber.replace("-", "").length < 11) {
          number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
          if (number?.length === 12) {
            this[event.target.name] = prefix + number;
          }
        }
        const cases = await CasesApi.searchStore.load({
          filter: ["accessionNumber", prefix.length ? "=" : "contains", prefix + number]
        });
        if (cases.length === 1 && cases[0]?.patientId) {
          this.caseNotFound = false;
          this.selectedPatientId = cases[0]?.patientId;
        } else {
          this.caseNotFound = true;
        }
      }
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    handleCancel() {
      this.$emit("close");
    },
    async handlePreview() {
      if (!this.isNewRecord && !this.selectedPatientId) {
        window.alert("Please select a patient record to attach case data.");
        return;
      }
      const payload = this.isNewRecord
        ? {
            caseIds: this.cases.map(e => e.caseId),
            patientId: this.cases[0].patientId
          }
        : {
            caseIds: this.cases.map(e => e.caseId),
            newPatientId: this.selectedPatientId
          };
      this.isLoading = true;
      const preview = await this.sendPayload({
        ...payload,
        previewOnly: true
      });
      const newPatientData = await CasesApi.getPatientById(
        payload?.patientId || payload?.newPatientId
      );
      if (newPatientData?.id) {
        this.newPatientName = newPatientData.firstName + " " + newPatientData.lastName;
      } else {
        this.newPatientName = "";
      }
      this.payload = payload;
      this.preview = preview.cases;
      this.togglePreview();
      this.isLoading = false;
    },
    async sendPayload(payload) {
      if (this.isNewRecord) {
        return await CaseHistoryApi.removeCases(payload);
      }
      return await CaseHistoryApi.moveCases(payload);
    },
    togglePreview() {
      if (this.isPreviewOpen) {
        this.newPatientName = "";
      }
      this.isPreviewOpen = !this.isPreviewOpen;
    },
    async handleCommit(event) {
      event.preventDefault();
      this.isLoading = true;
      try {
        const response = await this.sendPayload({
          ...this.payload,
          previewOnly: false
        });
        if (response?.cases?.length) {
          window.notify(
            `Unlinked ${response.cases.length} case${response.cases.length > 1 ? "s" : ""}.`
          );
          const logItem = createLogItem({}, AuditLogItems.Other);
          logItem.comments = `Moved case${response.cases.length > 1 ? "s" : ""} ${response.cases
            .map(e => e.caseNumber)
            .join(", ")} to ${
            this.isNewRecord ? "new" : "different"
          } patient record ${JSON.stringify(response, null, 2)}`;
          AuditLogApi.insertLogMessage(logItem);
          this.$emit("close");
          this.$emit("commit");
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleShortkey(event) {
      if (!this.isPreviewOpen) {
        this.handlePreview();
      } else {
        this.handleCommit(event);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

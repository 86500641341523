<template>
  <form
    class="address container p-2"
    v-shortkey="shortkeyAdd"
    @shortkey="addAddress"
    @submit.prevent.stop="addAddress"
  >
    <div class="d-flex align-items-center mt-2">
      <h5 class="col">Addresses</h5>
      <add-button class="ml-auto" type="submit" />
    </div>
    <template v-if="allAddresses">
      <div class="container my-3" v-for="(address, index) in allAddresses" :key="index">
        <div class="form-row my-2 align-items-center">
          <h5 class="col">{{ `Address (${index + 1})` }}</h5>
          <div class="col d-flex justify-content-end">
            <button type="button" class="btn" @click="setPrimary(index)">
              <template v-if="address.isPrimary">
                <icon class="text-primary" icon="parking" />
              </template>
              <template v-else>
                <span class="secondary-icon">S</span>
              </template>
            </button>
            <icon-button
              type="button"
              :disabled="allAddresses.length === 1 && !allowEmpty"
              @click="removeAddress(index, address)"
              icon="trash-alt"
              class="btn text-danger actions"
            />
          </div>
        </div>

        <text-input
          :validator="v && v.$each[index].line1"
          :required="true"
          name="line1"
          id="line1"
          label="Line 1"
          v-model="address.line1"
          :validatorMsgMap="validatorMsgMap"
          maxLength="101"
          data-private="redact"
          :tabindex="tabProp || 0"
        />

        <text-input
          maxLength="101"
          name="line2"
          id="line2"
          label="Line 2"
          :validator="v && v.$each[index].line2"
          :validatorMsgMap="validatorMsgMap"
          v-model="address.line2"
          data-private="redact"
          :tabindex="tabProp || 0"
        />

        <address-lookup v-model="allAddresses[index]" :tabProp="tabProp || 0" />

        <text-input
          name="international"
          v-model="address.international"
          label="International"
          maxLength="100"
          :tabindex="tabProp || 0"
        />
      </div>
    </template>
  </form>
</template>

<script>
import { validatorMsgMapBase, isIE, altKey } from "@/modules/helpers.js";
import AddressLookup from "../common/AddressLookup.vue";
import TextInput from "../common/TextInput.vue";
import { mapState } from "vuex";
import AddButton from "../common/AddButton.vue";
import IconButton from "../common/IconButton.vue";
import Icon from "../common/Icon.vue";
export default {
  name: "Address",
  props: {
    value: {
      required: true
    },
    index: {
      type: Number
    },
    v: {
      type: Object
    },
    tabProp: {
      type: Number
    },
    allowEmpty: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      isOpen: false,
      shortkeyAdd: altKey("a")
    };
  },
  components: {
    AddressLookup,
    TextInput,
    AddButton,
    IconButton,
    Icon
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(nv) {
        if (nv?.length) {
          const nonDeleted = nv.filter(e => !e.isDeleted);
          const hasPrimary = nonDeleted.find(e => e.isPrimary && !e.isDeleted);
          if (!hasPrimary) {
            for (const address of this.value) {
              if (!address?.isDeleted) {
                address.isPrimary = true;
                break;
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState({ states: state => state.dropdowns.states }),
    validatorMsgMap() {
      return validatorMsgMapBase;
    },
    allAddresses() {
      return this.value?.filter(e => !e.isDeleted);
    }
  },
  methods: {
    addAddress() {
      const isActive = this.$el.contains(document.activeElement);
      if (!isActive) {
        return;
      }
      if (this.value === null || this.value === undefined) {
        return this.$emit("input", [
          {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zipCode: "",
            isDeleted: false,
            international: "",
            isPrimary: true
          }
        ]);
      }
      if (!this.value.length || this.$el.checkValidity()) {
        const addresses = [
          ...this.value,
          {
            line1: "",
            line2: "",
            city: "",
            state: "",
            zipCode: "",
            isDeleted: false,
            international: "",
            isPrimary: this.value.length ? false : true
          }
        ];
        this.$emit("input", addresses);
      } else if (!isIE()) {
        this.$el.reportValidity();
      }
    },
    removeAddress(index, entry) {
      const address = this.value.find(e => e.id != undefined && e.id === entry.id);
      if (address) {
        address.isDeleted = true;
        return this.$emit("input", this.value);
      }
      const data = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
      return this.$emit("input", data);
    },
    setPrimary(index) {
      const data = this.value.map((address, i) => {
        if (i !== index) {
          address.isPrimary = false;
        } else {
          address.isPrimary = true;
        }
        return address;
      });
      this.$emit("input", data);
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  padding: 0.5rem;
}
.form-group {
  align-items: center;
}
.address {
  overflow-y: auto;
  max-height: 500px;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }
  /* Handle on hover */
  & ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>

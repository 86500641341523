<template>
  <div class="merge-modal-content">
    <h2>Merge Patient Records</h2>
    <form @submit="handlePreview" v-shortkey="submitShortkey" @shortkey="handleShortkey">
      <div class="mb-2">
        Select Patient Record to merge with {{ targetPatientData.firstName }}
        {{ targetPatientData.lastName }}:
      </div>
      <div class="row">
        <div class="col">
          <text-input
            v-focus
            label="Case Number"
            v-model="caseNumberSearch"
            name="caseNumberSearch"
            @blur="handleChangeCaseSearch"
          />
          <div class="ml-2 text-danger" v-if="caseNotFound">No matching case found</div>
        </div>
        <select-input
          data-private="redact"
          label="Patient Name"
          class="col"
          v-model="selectedTargetPatientId"
          name="selectedTargetPatientId"
          :searchExpr="['lastName', 'firstName']"
          :displayExpr="patientDropDownDisplay"
          :dataSource="patientDataSource"
        />
      </div>
      <div v-if="isSamePatient" class="text-danger">You have selected the same patient.</div>
      <div class="d-flex justify-content-between m-2">
        <div>
          All cases currently assigned to source patient will be assigned to target patient.
        </div>
        <!-- need better icon? -->
        <icon-button class="btn-primary" icon="undo" @click.prevent="switchPatients" />
      </div>
      <div class="compare-table">
        <div class="row">
          <div class="col cell"><b>Field</b></div>
          <div class="col cell"><b>Source Patient</b></div>
          <div class="col cell"><b>Target Patient</b></div>
        </div>
        <div class="row" v-for="field in fieldsToCompare" v-bind:key="field.displayName">
          <div class="col cell">{{ field.displayName }}</div>
          <div data-private="redact" class="col cell" :class="isMismatched(field.fieldName)">
            {{ getCellData(field.fieldName, true) }}
          </div>
          <div data-private="redact" class="col cell" :class="isMismatched(field.fieldName)">
            {{ getCellData(field.fieldName, false) }}
          </div>
        </div>
        <div class="my-2 d-flex justify-content-end align-items-center">
          <loader v-if="isLoading" size="small" />
          <button class="btn btn-danger" type="button" @click="handleCancel">Cancel</button>
          <button
            class="btn btn-success ml-2"
            type="submit"
            :disabled="!selectedTargetPatientId || isSamePatient"
          >
            Preview
          </button>
        </div>
      </div>
    </form>
    <modal :status="isPreviewOpen" @close="isPreviewOpen = false">
      <div v-if="casesPreview.length" class="p-1">
        <h3>
          The following cases will be assigned to the patient record for
          <span data-private="redact"
            >{{ targetPatientData.firstName }} {{ targetPatientData.lastName }}</span
          >:
        </h3>
        <div v-for="item of casesPreview" v-bind:key="item.id">
          {{ item.caseNumber }}
        </div>
      </div>
      <div v-else>This patient has no cases to assign.</div>
      <div class="my-2 d-flex justify-content-end align-items-center">
        <loader v-if="isLoading" size="small" />
        <button class="btn btn-danger" type="button" @click="isPreviewOpen = false">Cancel</button>
        <button class="btn btn-success ml-2" type="submit" @click="handleCommit">Commit</button>
      </div>
    </modal>
    <modal :status="isPotentialMatchesOpen" @close="closePotentialMatches">
      <div v-if="potentialMatches.length === 1">
        <div>
          Patient found with same name and date of birth. Do you want to select this patient?
        </div>
        <br />
        <div v-for="patient of potentialMatches" v-bind:key="patient.id">
          <div class="font-weight-bold">
            {{ patient.lastName }}, {{ patient.firstName }} ({{ formatDate(patient.dateOfBirth) }})
          </div>
          <div>
            <span v-if="patient.mrn"
              ><span class="font-weight-bold">MRN:</span> {{ patient.mrn }}
            </span>
            <span v-if="patient.ssn"
              ><span class="font-weight-bold">SSN:</span> {{ patient.ssn }}</span
            >
          </div>
          <br />
          <div>Select this patient?</div>
          <div class="d-flex justify-content-start">
            <button class="btn btn-primary m-1" @click="selectPotentialMatch(patient)">Yes</button>
            <button class="btn btn-danger m-1" @click="closePotentialMatches">No</button>
          </div>
        </div>
      </div>
      <div v-else class="w-75 m-auto">
        <div>Multiple matching patient records found. Would you like to select one?</div>
        <br />
        <button class="btn btn-danger m-2" @click="closePotentialMatches">No</button>
        <table>
          <thead>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>DOB</th>
              <th>MRN</th>
              <th>SSN</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="patient of potentialMatches"
              v-bind:key="patient.id"
              @click="selectPotentialMatch(patient)"
            >
              <td :class="checkCellMatch(patient, 'lastName')">
                {{ patient.lastName }}
              </td>
              <td :class="checkCellMatch(patient, 'firstName')">
                {{ patient.firstName }}
              </td>
              <td :class="checkCellMatch(patient, 'dob')">
                {{ formatDate(patient.dob) }}
              </td>
              <td :class="checkCellMatch(patient, 'mrn')">{{ patient.mrn }}</td>
              <td :class="checkCellMatch(patient, 'ssn')">{{ patient.ssn }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </modal>
  </div>
</template>

<script>
import DataSource from "devextreme/data/data_source";
import SelectInput from "./common/SelectInput.vue";
import TextInput from "./common/TextInput.vue";
import { AuditLogApi, CaseHistoryApi, CasesApi } from "@/services";
import moment from "moment";
import {
  altKey,
  calculateAccessionNumberingWithoutPrevCentury,
  createLogItem,
  formatSSN
} from "@/modules/helpers";
import { cloneDeep } from "lodash";
import IconButton from "./common/IconButton.vue";
import Modal from "./common/Modal.vue";
import { handleErrors } from "@/modules/handleErrors";
import Loader from "./common/Loader.vue";
import { AuditLogItems } from "@/modules/enums";

export default {
  components: { TextInput, SelectInput, IconButton, Modal, Loader },
  props: ["currentPatient"],
  data() {
    return {
      isLoading: false,
      caseNumberSearch: null,
      sourcePatientData: {
        id: null,
        mrn: null,
        ssn: null,
        dateOfBirth: null,
        firstName: null,
        lastName: null,
        maidenName: null,
        suffix: null,
        sex: null,
        race: null,
        accountNumber: null,
        lastProvider: null,
        address: [],
        email: [],
        phoneNumbers: []
      },
      selectedTargetPatientId: null,
      targetPatientData: {
        id: null,
        mrn: null,
        ssn: null,
        dateOfBirth: null,
        firstName: null,
        lastName: null,
        maidenName: null,
        suffix: null,
        sex: null,
        race: null,
        accountNumber: null,
        lastProvider: null,
        address: [],
        email: [],
        phoneNumbers: []
      },
      caseNotFound: false,
      fieldsToCompare: [
        { displayName: "First Name", fieldName: "firstName" },
        { displayName: "Last Name", fieldName: "lastName" },
        { displayName: "SSN", fieldName: "ssn" },
        { displayName: "DOB", fieldName: "dateOfBirth" },
        { displayName: "MRN", fieldName: "mrn" }
      ],
      isSwitched: false,
      casesPreview: [],
      isPreviewOpen: false,
      payloadToCommit: {},
      submitShortkey: altKey("s"),
      isSamePatient: false,
      potentialMatches: [],
      isPotentialMatchesOpen: false
    };
  },
  mounted() {
    this.targetPatientData = cloneDeep(this.currentPatient);
    this.findPotentialMatches();
  },
  computed: {
    patientDataSource() {
      return new DataSource({
        store: CasesApi.patientMatch,
        sort: "lastName",
        filter: ["!", ["id", "=", this.currentPatient.id]]
      });
    }
  },
  watch: {
    selectedTargetPatientId: {
      handler(nv, ov) {
        if (nv !== ov) {
          const defaultData = {
            id: null,
            mrn: null,
            ssn: null,
            dateOfBirth: null,
            firstName: null,
            lastName: null,
            maidenName: null,
            suffix: null,
            sex: null,
            race: null,
            accountNumber: null,
            lastProvider: null,
            address: [],
            email: [],
            phoneNumbers: []
          };
          if (nv) {
            CasesApi.getPatientById(nv).then(res => {
              if (!res?.id) {
                window.alert("Error loading patient data.");
                this.isSwitched
                  ? (this.targetPatientData = defaultData)
                  : (this.sourcePatientData = defaultData);
              } else {
                this.isSwitched ? (this.targetPatientData = res) : (this.sourcePatientData = res);
              }
            });
          } else {
            this.isSwitched
              ? (this.targetPatientData = defaultData)
              : (this.sourcePatientData = defaultData);
            this.caseNumberSearch = "";
          }
        }
      }
    },
    "targetPatientData.id": {
      handler(nv) {
        this.isSamePatient = nv === this.sourcePatientData?.id;
      }
    },
    "sourcePatientData.id": {
      handler(nv) {
        this.isSamePatient = nv === this.targetPatientData?.id;
      }
    }
  },
  methods: {
    patientDropDownDisplay(data) {
      if (!data) {
        return "";
      }
      return `${data?.lastName}, ${data?.firstName} ${
        data.dateOfBirth ? "(" + this.formatDate(data.dateOfBirth) + ")" : ""
      }`;
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    async handleChangeCaseSearch(event) {
      const { value } = event.target;
      let prefix = "";
      if (value) {
        if (this.hasPrefix(value)) {
          const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
          prefix = regex.groups.prefix.toUpperCase();
        }
        let targetNumber = this.caseNumberWithoutPrefix(value);
        let number = null;
        if (targetNumber.replace("-", "").length < 11) {
          number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
          if (number?.length === 12) {
            this[event.target.name] = prefix + number;
          }
        }
        const cases = await CasesApi.searchStore.load({
          filter: ["accessionNumber", prefix.length ? "=" : "contains", prefix + number]
        });
        if (cases.length === 1 && cases[0]?.patientId) {
          this.caseNotFound = false;
          this.selectedTargetPatientId = cases[0]?.patientId;
        } else {
          this.caseNotFound = true;
        }
      }
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    getCellData(fieldName, isCurrent) {
      let data = isCurrent ? this.sourcePatientData[fieldName] : this.targetPatientData[fieldName];
      if (fieldName === "dateOfBirth" && data) {
        data = this.formatDate(data);
      }
      if (fieldName === "ssn" && data) {
        data = formatSSN(data);
      }
      return data;
    },
    isMismatched(fieldName) {
      if (this.sourcePatientData[fieldName] && this.targetPatientData[fieldName]) {
        let sourceData = this.sourcePatientData[fieldName];
        let targetData = this.targetPatientData[fieldName];
        if (fieldName === "ssn") {
          sourceData = sourceData.replaceAll("-", "");
          targetData = targetData.replaceAll("-", "");
        }
        return sourceData === targetData ? "matching" : "mismatched";
      }
      return "";
    },
    handleCancel() {
      this.$emit("close");
    },
    async handlePreview(event) {
      event.preventDefault();
      if (!this.sourcePatientData?.id || !this.targetPatientData?.id) {
        window.alert("Please select a patient to merge.");
        return;
      }
      const payloadToCommit = {
        sourcePatientId: this.sourcePatientData.id,
        targetPatientId: this.targetPatientData.id
      };
      this.isLoading = true;
      const preview = await CaseHistoryApi.mergePatients({ ...payloadToCommit, previewOnly: true });
      this.payloadToCommit = payloadToCommit;
      this.casesPreview = preview.casesAffected;
      this.isPreviewOpen = true;
      this.isLoading = false;
    },
    switchPatients(event) {
      event.preventDefault();
      const newSource = cloneDeep(this.targetPatientData);
      const newTartget = cloneDeep(this.sourcePatientData);
      this.sourcePatientData = newSource;
      this.targetPatientData = newTartget;
      this.isSwitched = !this.isSwitched;
    },
    async handleCommit(event) {
      event.preventDefault();
      this.isLoading = true;
      try {
        const response = await CaseHistoryApi.mergePatients({
          ...this.payloadToCommit,
          previewOnly: false
        });
        if (response?.casesAffected?.length) {
          window.notify(
            `Successfully assigned ${response.casesAffected.length} cases to ${this.targetPatientData.firstName} ${this.targetPatientData.lastName}`
          );
        } else {
          window.notify("Successfully merged patient records");
        }
        const logItem = createLogItem({}, AuditLogItems.Other);
        logItem.comments = `Merged patient records for ${this.sourcePatientData.firstName} ${
          this.sourcePatientData.lastName
        } and ${this.targetPatientData.firstName} ${
          this.targetPatientData.lastName
        }. ${JSON.stringify(response, null, 2)}`;
        AuditLogApi.insertLogMessage(logItem);
        this.$emit("commit");
        this.$emit("close");
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    handleShortkey(event) {
      if (!this.isPreviewOpen) {
        this.handlePreview(event);
      } else {
        this.handleCommit(event);
      }
    },
    async findPotentialMatches() {
      const potentialMatches = await CasesApi.patientMatch.load({
        filter: [
          ["!", ["id", "=", this.currentPatient.id]],
          "and",
          [
            ["firstname", "=", this.targetPatientData.firstName],
            "and",
            ["lastName", "=", this.targetPatientData.lastName],
            "and",
            ["dateOfBirth", "=", this.targetPatientData.dateOfBirth]
          ]
        ]
      });
      if (potentialMatches.length) {
        this.potentialMatches = potentialMatches;
        this.isPotentialMatchesOpen = true;
      }
    },
    closePotentialMatches() {
      this.isPotentialMatchesOpen = false;
      this.potentialMatches = [];
    },
    selectPotentialMatch(data) {
      this.selectedTargetPatientId = data.id;
      this.closePotentialMatches();
    },
    checkCellMatch(patient, field) {
      if (patient[field] !== this.targetPatientData[field]) {
        return "text-danger";
      }
      return "";
    }
  }
};
</script>

<style lang="scss" scoped>
.merge-modal-content {
  width: 95%;
}
.compare-table {
  width: 90%;
  margin: auto;
}
.cell,
tr,
td {
  border-style: solid;
  border-color: black;
  border-width: 1px;
  padding: 0.25rem;
}
.mismatched {
  background-color: yellow;
}
.matching {
  background-color: lightgreen;
}
</style>

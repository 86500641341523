<template>
  <div v-shortkey="shortkeys" @shortkey="handleShortkey">
    <h2>
      Add cases to
      <span data-private="redact">{{ patientData.firstName }} {{ patientData.lastName }}</span>
      Record
    </h2>
    <text-input v-focus label="Case to Add" v-model="caseNumberSearch" ref="searchField" />
    <div class="my-2 d-flex justify-content-end align-items-center">
      <loader v-if="isLoadingCase" size="small" />
      <button class="btn btn-primary mx-2" @click="toggleSearchByName">Name Search</button>
      <button class="btn btn-primary" @click="addCaseToQueue" :disabled="!caseNumberSearch">
        Add Case
      </button>
    </div>
    <div v-if="!casesToAddToPatient.length" class="m-2">No cases selected.</div>
    <div v-for="caseDetails of casesToAddToPatient" v-bind:key="caseDetails.id">
      <div class="row">
        <div class="col-1">
          <icon-button
            icon="ban"
            @click="removeCase(caseDetails.caseId)"
            class="btn text-danger pointer p-0"
            v-tooltip="'Click to remove case.'"
          />
        </div>
        <div class="col-4 d-flex align-items-center">{{ caseDetails.accessionNumber }}</div>
        <div data-private="redact" class="col-6 d-flex align-items-center">
          {{ caseDetails.patientName }}
        </div>
      </div>
    </div>
    <div class="my-2 d-flex justify-content-end align-items-center">
      <loader v-if="isLoading" size="small" />
      <button class="btn btn-danger" type="button" @click="handleCancel">Cancel</button>
      <button
        class="btn btn-success ml-2"
        type="submit"
        @click="handlePreview"
        :disabled="!casesToAddToPatient.length"
      >
        Preview
      </button>
    </div>
    <modal :status="isPreviewOpen" @close="togglePreview">
      <h3>
        The following cases will be added to the patient record for
        <span data-private="redact">{{ patientData.firstName }} {{ patientData.lastName }}</span
        >:
      </h3>
      <div v-for="caseDetails of preview" v-bind:key="caseDetails.caseId">
        {{ caseDetails.caseNumber }}
      </div>
      <div class="my-2 d-flex justify-content-end align-items-center">
        <loader v-if="isLoading" size="small" />
        <button class="btn btn-danger" type="button" @click="togglePreview">Cancel</button>
        <button class="btn btn-success ml-2" type="submit" @click="handleCommit">Commit</button>
      </div>
    </modal>
    <modal :status="isSearchByNameOpen" @close="toggleSearchByName" maxWidth="75em">
      <h2>Search By Name</h2>
      <SelectInput
        v-focus
        data-private="redact"
        label="Patient Name"
        v-model="selectedPatientId"
        name="selectedPatientId"
        :searchExpr="['lastName', 'firstName']"
        :displayExpr="patientDropDownDisplay"
        :dataSource="patientDataSource"
      />
      <dx-grid-with-search
        v-if="selectedPatientId"
        :dataSource="historyDataSource"
        :columns="columns"
        :toolbar="toolbar"
        :columnChooser="false"
        :searchPanel="searchPanel"
        @initialized="initGrid"
        @content-ready="onContentReady"
        :onCellDblClick="handleAddNameCase"
      />
      <SubmitCancelRow
        submitText="Add Cases"
        :isDisabled="!selectedPatientId"
        @submit="handleAddNameCase"
        @cancel="toggleSearchByName"
      />
    </modal>
  </div>
</template>

<script>
import { AuditLogApi, CaseHistoryApi, CasesApi } from "@/services";
import TextInput from "./common/TextInput.vue";
import Modal from "./common/Modal.vue";
import {
  altKey,
  calculateAccessionNumberingWithoutPrevCentury,
  createLogItem,
  formatSSN
} from "@/modules/helpers";
import { handleErrors } from "@/modules/handleErrors";
import IconButton from "./common/IconButton.vue";
import Loader from "./common/Loader.vue";
import { AuditLogItems } from "@/modules/enums";
import SelectInput from "./common/SelectInput.vue";
import SubmitCancelRow from "./common/SubmitCancelRow.vue";
import DataSource from "devextreme/data/data_source";
import moment from "moment";
import DxGridWithSearch from "./common/DxGridWithSearch.vue";

export default {
  components: {
    TextInput,
    Modal,
    IconButton,
    Loader,
    SelectInput,
    SubmitCancelRow,
    DxGridWithSearch
  },
  props: ["patientData"],
  data() {
    return {
      caseNumberSearch: "",
      casesToAddToPatient: [],
      preview: [],
      isPreviewOpen: false,
      payloadForCasesToAdd: {},
      shortkeys: {
        enter: ["enter"],
        s: altKey("s")
      },
      isLoading: false,
      isLoadingCase: false,
      isSearchByNameOpen: false,
      selectedPatientId: null,
      caseOptions: [],
      grid: {}
    };
  },
  computed: {
    patientDataSource() {
      return new DataSource({
        store: CasesApi.patientMatch,
        sort: "lastName",
        filter: ["!", ["id", "=", this.patientData.id]]
      });
    },
    historyDataSource() {
      return new DataSource({
        store: this.selectedPatientId ? CasesApi.searchStore : [],
        filter: ["patientId", "=", this.selectedPatientId]
      });
    },
    columns() {
      return [
        {
          dataField: "accessionNumber",
          caption: "Case #"
        },
        {
          dataField: "patientName"
        },
        {
          dataField: "patientSSN",
          caption: "SSN",
          calculateDisplayValue: data => {
            if (data.patientSSN) {
              return formatSSN(data.patientSSN);
            }
            return "";
          }
        },
        {
          dataField: "patientDOB",
          caption: "DOB",
          dataType: "date"
        },
        {
          dataField: "patientMRN",
          caption: "MRN"
        }
        // Address
      ];
    }
  },
  methods: {
    calculateFullCaseNumber(caseNumber) {
      let prefix = "";
      if (this.hasPrefix(caseNumber)) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
        prefix = regex.groups.prefix.toUpperCase();
      }
      let targetNumber = this.caseNumberWithoutPrefix(caseNumber);
      let number = null;
      if (targetNumber.replace("-", "").length < 11) {
        number = calculateAccessionNumberingWithoutPrevCentury(targetNumber);
      }
      return { prefix, number };
    },
    caseNumberWithoutPrefix(caseNumber) {
      if (!caseNumber) {
        return "";
      }
      const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(caseNumber);
      return caseNumber.replace(regex?.groups?.prefix, "");
    },
    hasPrefix(value) {
      if (value) {
        const regex = /^(?<prefix>[a-z]{1,6})[0-9]/i.exec(value);
        return Boolean(regex?.groups?.prefix);
      }
      return false;
    },
    async addCaseToQueue() {
      if (!this.caseNumberSearch.length) {
        window.alert("Please enter a case number to search.");
        return;
      }
      this.isLoadingCase = true;
      const { prefix, number } = this.calculateFullCaseNumber(this.caseNumberSearch);
      const cases = await CasesApi.searchStore.load({
        filter: ["accessionNumber", prefix?.length ? "=" : "contains", prefix + number]
      });
      if (cases.length === 1) {
        if (cases[0]?.patientId !== this.selectedPatientId) {
          this.casesToAddToPatient.push(cases[0]);
          this.$refs.searchField.focus();
          this.$refs.searchField.selectAll();
        } else {
          window.alert(
            `Case ${cases[0].accessionNumber} is already assigned to the current patient.`
          );
        }
      } else {
        window.alert("No matching case found.");
      }
      this.isLoadingCase = false;
    },
    async handlePreview() {
      if (!this.casesToAddToPatient.length) {
        window.alert("No cases selected.");
        return;
      }
      const payloadForCasesToAdd = {
        newPatientId: this.patientData.id,
        caseIds: this.casesToAddToPatient.map(e => e.caseId)
      };
      this.isLoading = true;
      const preview = await CaseHistoryApi.moveCases({
        ...payloadForCasesToAdd,
        previewOnly: true
      });
      if (!preview?.cases?.length) {
        window.notify("Unable to add cases to patient record.");
        this.isLoading = false;
        return;
      }
      this.payloadForCasesToAdd = payloadForCasesToAdd;
      this.preview = preview.cases;
      this.isPreviewOpen = true;
      this.isLoading = false;
    },
    handleCancel() {
      this.$emit("close");
    },
    togglePreview() {
      this.isPreviewOpen = !this.isPreviewOpen;
    },
    async handleCommit(event) {
      event.preventDefault();
      this.isLoading = true;
      try {
        const response = await CaseHistoryApi.moveCases({
          ...this.payloadForCasesToAdd,
          previewOnly: false
        });
        if (response?.cases?.length) {
          window.notify(`Added ${response?.cases?.length} cases to patient record.`);
          const logItem = createLogItem({}, AuditLogItems.Other);
          logItem.comments = `Added case${response.cases.length > 1 ? "s" : ""} ${response.cases
            .map(e => e.caseNumber)
            .join(", ")} to patient record for ${this.patientData.firstName} ${
            this.patientData.lastName
          } ${JSON.stringify(response, null, 2)}`;
          AuditLogApi.insertLogMessage(logItem);
          this.$emit("commit");
          this.$emit("close");
        } else {
          window.alert("No cases were able to be added to patient record.");
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        this.isLoading = false;
      }
    },
    removeCase(caseId) {
      this.casesToAddToPatient = this.casesToAddToPatient.filter(e => e.caseId !== caseId);
    },
    handleShortkey(event) {
      const { srcKey } = event;
      if (srcKey === "enter") {
        this.addCaseToQueue();
      }
      if (srcKey === "s") {
        if (!this.isPreviewOpen) {
          this.handlePreview();
        } else {
          this.handleCommit(event);
        }
      }
    },
    toggleSearchByName() {
      this.isSearchByNameOpen = !this.isSearchByNameOpen;
      this.selectedPatientId = null;
    },
    patientDropDownDisplay(data) {
      if (!data) {
        return "";
      }
      return `${data?.lastName}, ${data?.firstName} ${
        data.dateOfBirth ? "(" + this.formatDate(data.dateOfBirth) + ")" : ""
      }`;
    },
    formatDate(date) {
      return moment(date).format("M/D/YYYY");
    },
    initGrid(event) {
      this.grid = event.component;
      // this.initialized$.next(event);
    },
    handleAddNameCase(data) {
      if (data?.key) {
        this.grid.selectRows([data.key], true);
      }
      let selectedRows = this.grid.getSelectedRowsData();
      const duplicateRows = selectedRows.filter(e =>
        this.casesToAddToPatient.map(e => e.caseId).includes(e.caseId)
      );
      if (duplicateRows?.length) {
        const isPlural = duplicateRows.length > 1;
        this.grid.deselectRows(duplicateRows.map(e => e.caseId));
        const message = `Case${isPlural ? "s" : ""} ${duplicateRows
          .map(e => e.accessionNumber)
          .join(", ")} ${isPlural ? "are" : "is"} already queued to be added to the case.`;
        if (duplicateRows.length === selectedRows.length) {
          window.alert(message);
          return;
        } else {
          window.notify(message, "warning");
          selectedRows = selectedRows.filter(
            e => !duplicateRows.map(e => e.caseId).includes(e.caseId)
          );
        }
      }
      if (!selectedRows.length) {
        window.alert("Please select cases to add.");
        return;
      }
      this.casesToAddToPatient.push(...selectedRows);
      this.toggleSearchByName();
    },
    onContentReady({ component }) {
      const rows = component.getVisibleRows();
      if (rows?.length === 1) {
        component.selectRowsByIndexes([0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
